import React from "react";
import { Link, PageProps } from "gatsby";
import Layout from "../components/Layout";

const About = (props: PageProps) => (
  <Layout>
    <h1>About</h1>
    <Link to={"/"}>Index</Link>
  </Layout>
);

export default About;
